<li class="max-w-full break-words">
    <a
        *ngIf="option.type === 'link'"
        href="{{ option.route }}"
        class="block px-4 py-[10px] rounded hover:bg-gray-100 dark:hover:bg-gray-600 text-left text-balance break-words"
        >{{ option.label }}</a
    >
    <div
        *ngIf="option.type === 'button'"
        (click)="action.emit()"
        class="flex cursor-pointer items-center w-full px-4 py-[6px] lg:py-[10px] rounded hover:bg-gray-100 dark:hover:bg-gray-600 select-none text-balance break-words"
    >
        {{ option.label | transloco }}
    </div>
    <div
        *ngIf="option.type === 'icon-button'"
        (click)="action.emit()"
        class="flex cursor-pointer items-center w-full px-4 py-[10px] rounded hover:bg-gray-100 dark:hover:bg-gray-600 select-none text-balance break-words"
    >
        <img src="{{ option.icon }} " class="size-4" alt="{{ option.label }}" />
        <span class="ml-2">{{ option.label | transloco }}</span>
    </div>
    <hr *ngIf="option.showHr" />
</li>
