<div class="relative w-full max-w-xl mx-auto h-full max-h-full z-20">
    <div class="flex flex-col w-full h-full items-center mt-[91px] select-none">
        <div class="max-w-full min-w-[391px] container-gradient-bg flex items-center justify-center py-4 px-30">
            <h4 class="text-white font-semibold text-[24px]">{{ constantService.i18n_constants.Web_Spin_Title | transloco }}
            </h4>
        </div>
        <div #app class="w-full h-full mt-28 flex items-center justify-center"></div>

        <button
            (click)="spinWheel()"
            class="w-[131px] h-10 bg-gradient-to-l from-[#26A7F0] to-[#BD00FF] flex items-center justify-center p-4 font-medium text-[14px] rounded-lg text-white"
        >
            {{ (constantService.i18n_constants.Web_Spin | transloco).toUpperCase() }}
        </button>
    </div>
</div>
