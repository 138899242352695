import { Container } from 'pixi.js';
import { ButtonEvents } from './ButtonEvents.mjs';

var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
class Button extends ButtonEvents {
  /**
   * Turns a given container-based view into a button by adding all button events.
   * @param {Container} view - instance of container, to be turned into button.
   */
  constructor(view) {
    super();
    /** Container, given as a constructor parameter that is a button view. */
    __publicField(this, "_view");
    if (view) {
      this.view = view;
      this.enabled = true;
    }
  }
  /** Set button view, that all the interaction events are applied to. */
  set view(view) {
    const wasItInitiated = !!this._view;
    if (wasItInitiated)
      this.disconnectEvents(this._view);
    this._view = view;
    this.connectEvents(this._view);
  }
  /** Get button view, thar all the interaction events are applied to. */
  get view() {
    return this._view;
  }
  /**
   * Switcher, which prevents all button events from firing if off.
   * @param {boolean} enabled
   */
  set enabled(enabled) {
    if (!this.view) {
      console.error("Button view is not set. Please set it before enabling the button.");
      return;
    }
    this.view.eventMode = enabled ? "static" : "auto";
    this.view.cursor = enabled ? "pointer" : "default";
    if (!enabled && this.isDown) {
      this.processUp();
    }
  }
  /** Getter that returns button state. */
  get enabled() {
    return this.view.eventMode === "static";
  }
}
class ButtonContainer extends Container {
  constructor(view) {
    super();
    __publicField(this, "button");
    __publicField(this, "onDown");
    __publicField(this, "onUp");
    __publicField(this, "onUpOut");
    __publicField(this, "onOut");
    __publicField(this, "onPress");
    __publicField(this, "onHover");
    this.button = new Button(this);
    this.button.enabled = true;
    if (view) {
      this.addChild(view);
    }
    this.onPress = this.button.onPress;
    this.onDown = this.button.onDown;
    this.onUp = this.button.onUp;
    this.onHover = this.button.onHover;
    this.onOut = this.button.onOut;
    this.onUpOut = this.button.onUpOut;
  }
  set enabled(enabled) {
    this.button.enabled = enabled;
  }
  get enabled() {
    return this.button.enabled;
  }
}

export { Button, ButtonContainer };

