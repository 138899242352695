import {Component, ElementRef, HostListener, inject, Input} from '@angular/core';
import {NgIf} from '@angular/common';
import {animate, style, transition, trigger} from '@angular/animations';
import {DropdownMenuService} from '@core/services/dropdown-menu/dropdown-menu.service';

@Component({
    selector: 'app-dropdown-menu',
    standalone: true,
    imports: [NgIf],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [style({opacity: 0}), animate('150ms', style({opacity: 1}))]),
            transition(':leave', [style({opacity: 1}), animate('150ms', style({opacity: 0}))]),
        ]),
    ],
    templateUrl: './dropdown-menu.component.html',
    styleUrl: './dropdown-menu.component.scss',
})
export class DropdownMenuComponent {
    dropdownMenuService = inject(DropdownMenuService);

    isActive: boolean = false;
    @Input() position: string = 'left';
    toggleMenu(event: Event) {
        event.stopPropagation(); // Prevent the document click event from firing
        this.isActive = !this.isActive; // Toggle the isActive state
        if (this.isActive) {
            this.dropdownMenuService.registerMenu(this);
            this.dropdownMenuService.closeOtherMenus(this);
        }
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: MouseEvent) {
        // Check if the clicked element is not within the dropdown menu
        // If so, close the menu
        if (!this.elementRef.nativeElement.contains(event.target) && this.isActive) {
            this.isActive = false;
            this.dropdownMenuService.popMenu(this);
        }
    }

    constructor(private elementRef: ElementRef) {}
}
