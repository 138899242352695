<header
    *ngIf="!hideHeader"
    class="sticky backdrop-blur-md bg-white dark:bg-[#141414] bg-opacity-60 dark:bg-opacity-60 top-0 z-50 flex flex-row items-center justify-center px-4 py-3"
>
    <!-- Desktop Menu  -->
    <div class="relative">
        <button
            *ngIf="screenWidth > 1024"
            id="dropdownButton"
            (click)="dropdownMenu.toggleMenu($event)"
            class="relative inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-full hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-gray-200 dark:text-white dark:focus:ring-gray-600 dark:focus:outline-none dark:hover:bg-gray-900"
        >
            <span class="sr-only">Open main menu</span>
            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M1 1h15M1 7h15M1 13h15"
                />
            </svg>
        </button>
        <app-dropdown-menu #dropdownMenu>
            <app-dropdown-menu-item
                [option]="{label: (constantService.i18n_constants.Dropdown_Live | transloco), type: 'link', route: '#'}"
            ></app-dropdown-menu-item>
            <app-dropdown-menu-item
                [option]="{
                    label: (constantService.i18n_constants.Dropdown_AboutUs | transloco),
                    type: 'link',
                    route: 'https://jiglelive.com'
                }"
            ></app-dropdown-menu-item>
            <app-dropdown-menu-item
                [option]="{
                    label: (constantService.i18n_constants.Dropdown_ToS | transloco),
                    type: 'link',
                    route: 'https://jiglelive.com/terms/ios/tos-ios-en.pdf'
                }"
            ></app-dropdown-menu-item>
            <app-dropdown-menu-item
                [option]="{
                    label: ('Dropdown_PrivacyPolicy' | transloco),
                    type: 'link',
                    route: 'https://jiglelive.com/privacy-policy/privacy-policy-en.pdf'
                }"
            ></app-dropdown-menu-item>
            <app-dropdown-menu-item
                [option]="{
                    label: (constantService.i18n_constants.Dropdown_CommunityGuideline | transloco),
                    type: 'link',
                    route: 'https://jiglelive.com/'
                }"
            ></app-dropdown-menu-item>
        </app-dropdown-menu>
    </div>

    <!-- Mobile Menu Button -->
    <button
        *ngIf="screenWidth < 1024"
        id="menuButton"
        (click)="toggleDropdownMenu()"
        class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-full hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-gray-200 dark:text-white dark:focus:ring-gray-600 dark:focus:outline-none dark:hover:bg-gray-900"
    >
        <span class="sr-only">{{ constantService.i18n_constants.Open_Main_Menu | transloco }}</span>
        <svg
            *ngIf="!isMobileMenuOpen"
            @fadeIn
            class="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
        >
            <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 1h15M1 7h15M1 13h15"
            />
        </svg>
        <img
            *ngIf="isMobileMenuOpen"
            src="assets/images/shared/icons/{{ themeService.isDarkMode ? 'white' : 'dark' }}-close-icon.svg"
            alt="close-icon"
        />
    </button>

    <span class="example-spacer"></span>
    <div (click)="routeToHome()" class="sm:absolute sm:left-1/2 sm:transform sm:translate-x-[-50%]">
        <img
            class="cursor-pointer hover:scale-110 transition-transform duration-300 ease-in-out"
            src="assets/images/shared/logo.svg"
            alt="logo-white"
        />
    </div>

    <span class="example-spacer"></span>

    <button
        *ngIf="!environment.production"
        (click)="routeToLeaderboard()"
        class="cursor-pointer md:hover:scale-125 transform transition-transform duration-300 pr-1 dark:text-white text-black"
    >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.25 13.716H4C3.035 13.716 2.25 14.501 2.25 15.466V20.966C2.25 21.931 3.035 22.716 4 22.716H7.25V13.716ZM13.5 11.216H10.5C9.535 11.216 8.75 12.001 8.75 12.966V22.716H15.25V12.966C15.25 12.001 14.465 11.216 13.5 11.216ZM20 16.216H16.75V22.716H20C20.965 22.716 21.75 21.931 21.75 20.966V17.966C21.75 17.001 20.965 16.216 20 16.216ZM16.334 4.364C16.246 4.093 16.011 3.894 15.729 3.853L13.617 3.547L12.673 1.634C12.421 1.122 11.58 1.122 11.328 1.634L10.384 3.547L8.272 3.853C7.99 3.894 7.755 4.093 7.667 4.364C7.579 4.635 7.652 4.933 7.857 5.133L9.385 6.622L9.024 8.724C8.976 9.006 9.091 9.291 9.322 9.458C9.553 9.627 9.859 9.648 10.112 9.515L12.001 8.522L13.889 9.515C13.999 9.573 14.118 9.601 14.238 9.601C14.393 9.601 14.548 9.553 14.679 9.458C14.91 9.291 15.025 9.006 14.977 8.724L14.616 6.622L16.144 5.133C16.349 4.933 16.422 4.635 16.334 4.364Z"
                fill="currentColor"
            />
        </svg>
    </button>

    <button
        class="cursor-pointer md:hover:scale-125 transform transition-transform duration-300 pr-1 text-black dark:text-white"
        (click)="themeService.toggleDarkTheme()"
    >
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32">
            <path
                fill="currentColor"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
                d="M25 18.623A9.225 9.225 0 1 1 13.377 7 9.226 9.226 0 0 0 25 18.623"
            ></path>
        </svg>
    </button>
    <button
        *ngIf="authService.isSkipped"
        (click)="openLoginComponent()"
        class="login-button px-4 py-1.5 h-10 rounded-lg flex flex-row items-center justify-center gap-[6px]"
    >
        <img src="assets/images/shared/icons/log-in.svg" alt="login" class="w-5 h-5" />
        <p class="text-white font-medium text-[14px] text-center">{{ i18nConstants.Login_Login | transloco }}</p>
    </button>
    <div *ngIf="!authService.isSkipped" class="flex flex-row items-center justify-center">
        <div
            (click)="openPurchaseModal()"
            class="bg-[#F0F4FF] rounded-full p-1 flex flex-row items-center gap-2 dark:bg-neutral-800 dark:text-white select-none cursor-pointer hover:scale-110 transition-transform duration-300 ease-in-out drop-shadow-md"
        >
            <div class="flex grow items-center">
                <img src="assets/images/shared/crystals.svg" alt="crystals" class="px-[2px]" />
                <p class="text-xs font-medium">
                    {{ convertCoin(authService.appSession$.value?.userDetail?.balance || 0) }}
                </p>
            </div>
            <div *ngIf="!isAgent">
                <img src="assets/images/shared/icons/plus.svg" alt="Top up" />
            </div>
        </div>
        <div class="relative flex items-center justify-center">
            <button (click)="logoutMenu.toggleMenu($event)">
                <img
                    *ngIf="authService.appSession$.value?.userDetail?.photo"
                    src="{{ authService.appSession$.value?.userDetail?.photo }}"
                    alt="profile"
                    class="mx-1.5 w-8 h-8 lg:w-10 lg:h-10 rounded-full cursor-pointer drop-shadow-xl object-cover"
                />
            </button>
            <app-dropdown-menu [position]="'right'" #logoutMenu>
                <app-dropdown-menu-item
                    [option]="{
                        label: 'Auth_Logout',
                        type: 'icon-button',
                        icon: 'assets/images/shared/icons/logout.svg'
                    }"
                    (action)="logout()"
                ></app-dropdown-menu-item>
            </app-dropdown-menu>
        </div>
    </div>
</header>

<!-- Mobile Menu -->
<div
    @fadeRollIn
    *ngIf="screenWidth < 1024 && isMobileMenuOpen"
    class="bottom-0 top-14 left-0 right-0 fixed w-full h-full overflow-y-auto bg-white dark:bg-[#141414] overscroll-contain z-50"
>
    <div class="flex flex-col h-full divide-y divide-gray-100">
        <ul class="p-3 space-y-1 text-sm font-medium text-gray-700 dark:text-gray-200" aria-labelledby="dropdownButton">
            <li class="py-4">
                <a href="#" class="block p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">{{
                    constantService.i18n_constants.Dropdown_Live | transloco
                }}</a>
            </li>
            <li class="py-4">
                <a href="https://cikcik.app" class="block p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                    {{ constantService.i18n_constants.Dropdown_AboutUs | transloco }}</a
                >
            </li>
            <li class="py-4">
                <a
                    href="https://cikcik.app/ios-tos-en.html"
                    class="block p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600"
                    >{{ constantService.i18n_constants.Dropdown_ToS | transloco }}</a
                >
            </li>
            <li class="py-4">
                <a
                    href="https://cikcik.app/privacyPolicy.html"
                    class="block p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600"
                    >{{ constantService.i18n_constants.Dropdown_PrivacyPolicy | transloco }}</a
                >
            </li>
            <li class="py-4">
                <a href="#" class="block p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">{{
                    constantService.i18n_constants.Dropdown_CommunityGuideline | transloco
                }}</a>
            </li>
        </ul>
        <!--        <div class="py-8 p-2 flex flex-row">-->
        <!--            <div-->
        <!--                (click)="toggleDropdownMenu(); openSpinWheel()"-->
        <!--                class="p-2 flex flex-row items-center justify-center cursor-pointer"-->
        <!--            >-->
        <!--                <img src="assets/images/shared/icons/spin-wheel.svg" alt="buy crystals" />-->
        <!--                <p class="text-gray-700 text-sm font-medium dark:text-white px-2">-->
        <!--                    {{ constantService.i18n_constants.Web_Spin | transloco }}-->
        <!--                </p>-->
        <!--            </div>-->
        <!--        </div>-->

        <div *ngIf="!isAgent" class="py-8 p-2 flex flex-row">
            <div
                (click)="toggleDropdownMenu(); openPurchaseModal()"
                class="p-2 flex flex-row items-center justify-center cursor-pointer"
            >
                <img src="assets/images/shared/icons/crystals.svg" alt="buy crystals" />
                <p class="text-gray-700 text-sm font-medium dark:text-white px-2">
                    {{ constantService.i18n_constants.Buy_Crystal | transloco }}
                </p>
            </div>
        </div>
        <div class="py-8 p-2 flex flex-row">
            <div class="w-40 items-center justify-center cursor-pointer">
                <div
                    (click)="toggleDropdownMenu(); openGetAppModal()"
                    class="h-10 w-full rounded-lg bg-gradient-to-r from-[#BD00FF] to-[#26A7F0] p-0.5"
                >
                    <div
                        class="flex h-full rounded-[7px] w-full items-center justify-center bg-white dark:bg-[#141414] back"
                    >
                        <p class="text-sm font-black text-gray-700 dark:text-white">
                            {{ constantService.i18n_constants.Download_App | transloco }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
