import {Component, ElementRef, OnDestroy, OnInit, ViewChild, inject} from '@angular/core';
import {NgForOf} from '@angular/common';
import * as PIXI from 'pixi.js';
import {FancyButton} from '@pixi/ui';
import {WheelPrizes} from '@shared/components/prize-wheel/WheelPrizes';
import * as TWEEN from '@tweenjs/tween.js';
import {WheelPrizeService} from '@core/services/wheel-prize/wheel-prize.service';
import { ConstantService } from '@core/services/common/constant.service';
import { TranslocoPipe } from '@ngneat/transloco';

@Component({
    selector: 'app-prize-wheel',
    standalone: true,
    imports: [NgForOf, TranslocoPipe],
    templateUrl: './prize-wheel.component.html',
    styleUrl: './prize-wheel.component.scss',
})
export class PrizeWheelComponent implements OnInit, OnDestroy {
    pixiApp!: PIXI.Application;

    sliceContainer!: PIXI.Container;
    wheelArrow!: PIXI.Sprite;

    spinTime = 4;
    spinSpeed = 10;

    isSpinning = false;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sprites!: Record<string, any>;

    constantService = inject(ConstantService);

    constructor(private wheelPrizeService: WheelPrizeService) {}

    @ViewChild('app') appContainer!: ElementRef;
    async ngOnInit() {
        await this.initializeApp();
    }

    ngOnDestroy() {
        this.destroyApp();
    }

    destroyApp() {
        this.pixiApp.destroy(true);
    }

    async initializeApp() {
        this.pixiApp = new PIXI.Application();
        await this.pixiApp.init({
            backgroundAlpha: 0,
            antialias: true,
            width: 400,
            height: 200,
            resolution: 2,
        });

        this.appContainer.nativeElement.appendChild(this.pixiApp.canvas);
        this.pixiApp.ticker.add(() => {
            TWEEN.default.update();
        });
        this.pixiApp.stage.scale.set(0.5);
        await this.loadAssets();

        this.prepareScreen();
    }

    prepareScreen() {
        const wheelFrame = new PIXI.Sprite(this.sprites['wheel-frame']);

        const wheelContainer = new PIXI.Container();

        wheelContainer.position.set(this.pixiApp.screen.width, this.pixiApp.screen.height);
        const wheelBg = new PIXI.Graphics();

        wheelBg.circle(0, 0, 140);
        wheelBg.pivot.set(0.5);
        wheelBg.fill({color: 0x270750});
        wheelBg.zIndex = 2;
        wheelContainer.addChild(wheelBg);

        wheelFrame.anchor.set(0.5);
        wheelFrame.x = 0;
        wheelFrame.y = -3;
        wheelFrame.zIndex = 3;
        wheelContainer.addChild(wheelFrame);

        this.wheelArrow = new PIXI.Sprite(this.sprites['wheel-prize-arrow']);
        this.wheelArrow.anchor.set(0.5);
        this.wheelArrow.position.set(0, wheelFrame.bounds.minY + 5);
        this.wheelArrow.zIndex = 4;

        wheelContainer.addChild(this.wheelArrow);

        const numberOfSlices = WheelPrizes.length;
        const radius = 65;
        this.sliceContainer = this.createSliceContainer(numberOfSlices, radius);

        const spinButton = this.createSpinButton();
        spinButton.onclick = () => {
            this.spinWheel();
        };

        wheelContainer.addChild(this.sliceContainer);
        wheelContainer.addChild(spinButton);

        this.pixiApp.stage.addChild(wheelContainer);
    }

    createSpinButton() {
        PIXI.BitmapFontManager.install({name: 'TitleFont', style: {fill: 0xffffff}});

        const title = new PIXI.BitmapText({
            text: 'SPIN',
            style: {
                fontFamily: 'TitleFont',
                fontSize: 16,
            },
        });

        const spinButton = new FancyButton({
            defaultView: 'spin-button-on',
            text: title,
            animations: {
                hover: {
                    props: {
                        scale: {x: 1.05, y: 1.05},
                    },
                    duration: 100,
                },
                pressed: {
                    props: {
                        scale: {x: 0.95, y: 0.95},
                    },
                    duration: 100,
                },
            },
        });
        spinButton.position.set(0, 0);
        spinButton.anchor.set(0.5, 0.5);
        spinButton.zIndex = 4;
        return spinButton;
    }

    createSliceContainer(numberOfSlices: number, r: number) {
        const sliceContainer = new PIXI.Container();
        const sliceTexture = this.sprites['wheel-slice']; // Get the texture once to avoid repetitive texture lookup
        const sliceAngle = 360 / numberOfSlices;
        const radius = r;
        for (let i = 0; i < numberOfSlices; i++) {
            const perSliceContainer = new PIXI.Container();
            const coinAmount = WheelPrizes[i].toString();
            const coinContainer = this.createCoinContainer(coinAmount);

            const slice = new PIXI.Sprite(sliceTexture);
            slice.anchor.set(0.5);
            const angle = sliceAngle * i;
            const radian = (angle * Math.PI) / 180;
            const sliceX = radius * Math.sin(radian);
            const sliceY = -(radius * Math.cos(radian));
            perSliceContainer.position.set(sliceX, sliceY);
            perSliceContainer.angle = sliceAngle * i; // Adjust rotation to make slices align correctly
            perSliceContainer.zIndex = 2;
            perSliceContainer.addChild(slice);

            coinContainer.position.set(0, slice.bounds.minY + 25);

            perSliceContainer.addChild(coinContainer);
            sliceContainer.addChild(perSliceContainer);
        }
        sliceContainer.position.set(0, -3);
        sliceContainer.zIndex = 2;
        return sliceContainer;
    }

    createCoinContainer(amount: string) {
        const coinSprite = new PIXI.Sprite(this.sprites['coin']);
        const coinText = new PIXI.Text({
            text: amount,
            style: {
                fontSize: 12,
                fill: 0xffffff,
            },
        });
        const coinContainer = new PIXI.Container();
        coinContainer.position.set(0, 0);
        coinSprite.anchor.set(0.5);
        coinSprite.position.set(0, 0);
        coinText.anchor.set(0.5);
        coinText.position.set(0, coinSprite.height);
        coinContainer.addChild(coinSprite);
        coinContainer.addChild(coinText);
        return coinContainer;
    }

    async loadAssets() {
        PIXI.Assets.add({alias: 'wheel-frame', src: '../assets/images/shared/prize-wheel/wheel-frame.png'});
        PIXI.Assets.add({alias: 'wheel-slice', src: '../assets/images/shared/prize-wheel/wheel-prize-slice.svg'});
        PIXI.Assets.add({alias: 'coin', src: '../assets/images/shared/prize-wheel/wheel-crystal.png'});
        PIXI.Assets.add({alias: 'wheel-prize-arrow', src: '../assets/images/shared/prize-wheel/wheel-prize-arrow.png'});
        PIXI.Assets.add({alias: 'spin-button-on', src: '../assets/images/shared/prize-wheel/wheel-spin-button-on.png'});

        this.sprites = await PIXI.Assets.load([
            'wheel-frame',
            'coin',
            'wheel-slice',
            'spin-button-on',
            'wheel-prize-arrow',
        ]);
    }

    animateSliceContainer(sliceIndex: number = 6) {
        if (this.isSpinning) return;
        const rotationDegree = sliceIndex * (360 / WheelPrizes.length) + 360 * this.spinSpeed;
        const rotationDegreeToRad = (rotationDegree * Math.PI) / 180;
        const spin = new TWEEN.default.Tween(this.sliceContainer)
            .to({rotation: rotationDegreeToRad}, this.spinTime * 2000)
            .easing(TWEEN.default.Easing.Quadratic.InOut)
            .start();

        spin.onStart(() => {
            this.isSpinning = true;
        });

        spin.onUpdate(() => {
            const currentRotation = this.sliceContainer.angle;
            const degree = 360 / WheelPrizes.length;
            const between = (currentRotation % degree) / degree;
            const isBetween = between >= 0.4 && between <= 0.6;
            if (isBetween) {
                this.animateArrow();
            }
        });
        spin.onComplete(() => {
            this.sliceContainer.rotation = (sliceIndex * (360 / WheelPrizes.length) * Math.PI) / 180;
            this.isSpinning = false;
        });
    }

    animateArrow() {
        const spin = new TWEEN.default.Tween(this.wheelArrow)
            .to({angle: -45}, 100)
            .easing(TWEEN.default.Easing.Quadratic.InOut)
            .start();

        spin.onComplete(() => {
            new TWEEN.default.Tween(this.wheelArrow)
                .to({angle: 0}, 100)
                .easing(TWEEN.default.Easing.Quadratic.InOut)
                .start();
        });
    }

    spinWheel() {
        this.wheelPrizeService.spinWheel().subscribe(res => {
            if (res.coin) {
                const sliceIndex = WheelPrizes.length - WheelPrizes.findIndex(prize => prize === res.coin);
                this.animateSliceContainer(sliceIndex);
            }
        });
    }
}
