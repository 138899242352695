import { Sprite } from 'pixi.js';

function getView(view) {
  if (typeof view === "string") {
    return Sprite.from(view);
  }
  return view;
}
function getSpriteView(view) {
  if (typeof view === "string") {
    return Sprite.from(view);
  }
  return view;
}

export { getSpriteView, getView };

