import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {SpinResponse} from '@core/models/wheel-prize/spin-response';

@Injectable({
    providedIn: 'root',
})
export class WheelPrizeService {
    http = inject(HttpClient);
    baseUrl!: string;

    constructor() {
        this.baseUrl = environment.apiUrl + '/wheel-prize';
    }

    spinWheel() {
        return this.http.post<SpinResponse>(this.baseUrl + '/define', {});
    }
}
