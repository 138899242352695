import {Injectable} from '@angular/core';
import {DropdownMenuComponent} from '@shared/components/dropdown-menu/dropdown-menu.component';

@Injectable({
    providedIn: 'root',
})
export class DropdownMenuService {
    private activeMenus: DropdownMenuComponent[] = [];

    constructor() {}

    registerMenu(menu: DropdownMenuComponent) {
        this.activeMenus.push(menu);
    }

    popMenu(menu: DropdownMenuComponent) {
        const index = this.activeMenus.indexOf(menu);
        if (index > -1) {
            this.activeMenus.splice(index, 1);
        }
    }

    closeOtherMenus(menuToKeepOpen: DropdownMenuComponent) {
        this.activeMenus.forEach(menu => {
            if (menu !== menuToKeepOpen) {
                menu.isActive = false;
            }
        });
    }
}
