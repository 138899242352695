<div
    @fadeInOut
    *ngIf="isActive"
    class="z-50 absolute min-w-[144px] max-w-48  h-auto top-11 {{
        position === 'left' ? 'left-0' : 'right-0'
    }} rounded-lg order-1 bg-white shadow-md dark:bg-neutral-800 p-2"
>
    <ul class="w-full text-nowrap space-y-1 text-sm font-medium text-gray-700 dark:text-gray-200 max-w-full">
        <ng-content></ng-content>
    </ul>
</div>
