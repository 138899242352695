import {Component, inject} from '@angular/core';
import {ModalCloseButtonComponent} from '@core/components/ui-components/modal-close-button/modal-close-button.component';
import {NgIf} from '@angular/common';
import {QRCodeModule} from 'angularx-qrcode';
import {ConstantService} from '@core/services/common/constant.service';
import {TranslocoPipe} from '@ngneat/transloco';

@Component({
    selector: 'app-get-app',
    standalone: true,
    imports: [ModalCloseButtonComponent, NgIf, QRCodeModule, TranslocoPipe],
    templateUrl: './get-app.component.html',
    styleUrl: './get-app.component.scss',
})
export class GetAppComponent {
    appQrHash = 'cikcik.app';
    constantService = inject(ConstantService);
    PLAY_STORE = 'https://play.google.com/store/apps/details?id=com.fun2lite.app';
    APP_STORE = 'https://apps.apple.com/us/app/cikcik-live-share-connect/id1521452500';
    hrefToAppStore() {
        window.location.href = this.APP_STORE;
    }

    hrefToPlayStore() {
        window.location.href = this.PLAY_STORE;
    }
}
