<div class="relative p-4 w-full max-w-xl max-h-full mx-auto">
    <app-mt-modal-close-button></app-mt-modal-close-button>
    <!-- Modal content -->
    <div class="relative flex items-center justify-center flex-col bg-white rounded-lg shadow dark:bg-gray-700">
        <div
            class="flex flex-col items-center justify-center px-4 py-8 md:pt-10 md:pb-8 rounded-t dark:border-gray-600"
        >
            <h3 class="text-[18px] font-medium text-black text-center dark:text-white">
                {{ constantService.i18n_constants.Get_App_Title | transloco }}
            </h3>
        </div>
        <!-- Modal body -->
        <div class="px-4 pb-4 md:px-8 md:pb-8">
            <div class="flex flex-col w-full items-center justify-center">
                <div class="flex items-center justify-center bg-[#F8FAFF] rounded-lg dark:bg-[#4E5A6C]">
                    <qrcode [qrdata]="appQrHash" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
                </div>
            </div>
        </div>

        <!--- Modal Footer--->
        <div class="px-4 pb-4 md:px-8 md:pb-8">
            <div class="flex flex-col w-full items-center justify-center gap-6">
                <div class="flex items -center justify-center w-full">
                    <p class="text-[16px] text-[#7E8594] dark:text-gray-300 text-center">
                        {{ constantService.i18n_constants.Get_App_Footer | transloco }}
                    </p>
                </div>
                <div class="flex flex-row gap-4 items-center justify-between">
                    <button (click)="hrefToAppStore()" class="select-none">
                        <img
                            src="assets/images/shared/get-app-badges/download-on-app-store.svg"
                            alt="Download on App Store"
                            class="w-32 h-10 pointer-events-none"
                        />
                    </button>
                    <button (click)="hrefToPlayStore()" class="select-none">
                        <img
                            src="assets/images/shared/get-app-badges/get-it-on-google-play.png"
                            alt="Get it on Google Play"
                            class="w-32 h-10"
                        />
                    </button>
                </div>
            </div>

            <!--- buttons will added --->
        </div>
    </div>
</div>
