import { Container, ObservablePoint, Ticker, Rectangle, NineSliceSprite, Texture, isMobile } from 'pixi.js';
import { Group, Tween } from 'tweedle.js';
import { ButtonContainer } from './Button.mjs';
import { fitToView } from './utils/helpers/fit.mjs';
import { getTextView } from './utils/helpers/text.mjs';
import { getView } from './utils/helpers/view.mjs';

var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
class FancyButton extends ButtonContainer {
  /**
   * Creates a button with a lot of tweaks.
   * @param {object} options - Button options.
   * @param {Container} options.defaultView - Container-based view that is shown when non of the button events are active.
   * @param {Container} options.hoverView - Container-based view that is shown when the mouse hovers over the button.
   * @param {Container} options.pressedView - Container-based view, shown when the mouse press on the component.
   * @param {Container} options.disabledView - Container-based view shown when the button is disabled.
   * @param {Container} options.icon - Container-based view for the button icon.
   * @param {Text} options.text - Text-based view for the button text.
   * @param {number} options.padding - Padding of the button text and icon views.
   * If button text or icon does not fit active view + padding it will scale down to fit.
   * @param {Point} options.offset - Offset of the button state views.
   * @param {Point} options.textOffset - Offset of the text view.
   * @param {Point} options.iconOffset - Offset of the icon view.
   * @param {number} options.scale - Scale of the button. Scale will be applied to a main container,
   * when all animations scales will be applied to the inner view.
   * @param {number} options.defaultTextScale - Base text scaling to take into account when fitting inside the button.
   * @param {number} options.defaultIconScale - Base icon scaling to take into account when fitting inside the button.
   * @param {number} options.anchor - Anchor point of the button.
   * @param {number} options.anchorX - Horizontal anchor point of the button.
   * @param {number} options.anchorY - Vertical anchor point of the button.
   * @param options.animations - Animations that will be played when the button state changes.
   */
  constructor(options) {
    super();
    __publicField(this, "animations");
    __publicField(this, "originalInnerViewState");
    __publicField(this, "defaultDuration", 100);
    /** FancyButton options. */
    __publicField(this, "options");
    /** Padding of the button text view. If button text does not fit active view + padding it will scale down to fit. */
    __publicField(this, "_padding");
    /** Offset of the button state views. If state views have different sizes, this option can help adjust them. */
    __publicField(this, "_offset");
    /** Offset of the text view. Can be set to any state of the button. */
    __publicField(this, "_textOffset");
    /** Offset of the icon view. Can be set to any state of the button. */
    __publicField(this, "iconOffset");
    //* View that holds all button inner views */
    __publicField(this, "innerView", new Container());
    __publicField(this, "_views", {});
    /** State of the button. Possible valuers are: 'default', 'hover', 'pressed', 'disabled' */
    __publicField(this, "state");
    /** Anchor point of the button. */
    __publicField(this, "anchor");
    /** Base text scaling to take into account when fitting inside the button */
    __publicField(this, "_defaultTextScale", { x: 1, y: 1 });
    /** Base icon scaling to take into account when fitting inside the button */
    __publicField(this, "_defaultIconScale", { x: 1, y: 1 });
    this.options = options ?? {};
    const {
      defaultView,
      hoverView,
      pressedView,
      disabledView,
      text,
      padding,
      offset,
      textOffset,
      iconOffset,
      defaultTextScale: textScale,
      defaultIconScale: iconScale,
      scale,
      anchor,
      anchorX,
      anchorY,
      icon,
      animations
    } = options ?? {};
    this.addChild(this.innerView);
    this.anchor = new ObservablePoint({
      _onUpdate: () => this.updateAnchor()
    });
    this.anchor.set(anchorX ?? anchor ?? 0, anchorY ?? anchor ?? 0);
    this.padding = padding ?? 0;
    this.offset = offset;
    this.textOffset = textOffset;
    this.iconOffset = iconOffset;
    this.defaultTextScale = textScale;
    this.defaultIconScale = iconScale;
    this.scale.set(scale ?? 1);
    if (animations) {
      this.animations = animations;
      Ticker.shared.add(() => Group.shared.update());
    }
    this.setState("default");
    this.defaultView = defaultView;
    this.hoverView = hoverView;
    this.pressedView = pressedView;
    this.disabledView = disabledView;
    this.text = text;
    this.iconView = icon;
    this.initStateControl();
  }
  /**
   * Updates the text of the button and updates its scaling basing on the new size.
   * @param {string | number} text
   */
  set text(text) {
    if (!text || text === 0) {
      this.removeView("textView");
      return;
    }
    if (!this._views.textView) {
      this.createTextView(text);
      return;
    }
    this._views.textView.text = text.toString();
  }
  /** Returns the text string of the button text element. */
  get text() {
    return this._views.textView?.text;
  }
  /**
   * Setter, that prevents all button events from firing.
   * @param {boolean} enabled
   */
  set enabled(enabled) {
    this.button.enabled = enabled;
    this.setState(enabled ? "default" : "disabled");
  }
  get enabled() {
    return this.button.enabled;
  }
  /**
   * Updates button state and shows the according views.
   *
   * Updates positions and offsets of the views.
   *
   * Plays animations if they are set.
   * @param {State} newState
   * @param force
   */
  setState(newState, force = false) {
    if (!force && this.state === newState) {
      return;
    }
    const currentView = this.getStateView(this.state);
    if (currentView)
      currentView.visible = false;
    this.state = newState;
    const activeView = this.getStateView(newState);
    if (activeView) {
      this.setOffset(activeView, newState, this.offset);
      activeView.visible = true;
    }
    this.updateAnchor();
    this.playAnimations(newState);
  }
  /**
   *
   * Manage button text view.
   * @param {string | Text} text - can be a string, Text, BitmapText ot HTMLText (Container-based element).
   */
  createTextView(text) {
    this._views.textView = getTextView(text);
    if (this.options?.defaultTextScale === void 0) {
      const { x, y } = this._views.textView.scale;
      this._defaultTextScale = { x, y };
    }
    this._views.textView.anchor.set(0);
    this.innerView.addChild(this._views.textView);
    this.adjustTextView(this.state);
  }
  /**
   * Manages views offsets if it's set.
   * @param view
   * @param state
   * @param offset
   */
  setOffset(view, state, offset) {
    const stateOffset = offset ? offset[state] : {
      x: 0,
      y: 0
    };
    const defaultStateOffset = offset?.default;
    if (stateOffset) {
      view.x += stateOffset.x ?? 0;
      view.y += stateOffset.y ?? 0;
    } else if (defaultStateOffset) {
      view.x += defaultStateOffset.x ?? 0;
      view.y += defaultStateOffset.y ?? 0;
    } else if (offset.x || offset.y) {
      view.x += offset.x ?? 0;
      view.y += offset.y ?? 0;
    }
  }
  /**
   * Returns active view for the state.
   * @param state
   */
  getStateView(state) {
    if (!this._views)
      return void 0;
    switch (state) {
      case "hover":
        return this._views.hoverView ?? this._views.defaultView ?? void 0;
      case "pressed":
        return this._views.pressedView ?? this._views.hoverView ?? this._views.defaultView ?? void 0;
      case "disabled":
        return this._views.disabledView ?? this._views.defaultView ?? void 0;
      case "default":
        return this._views.defaultView ?? void 0;
      default:
        return void 0;
    }
  }
  /**
   * Adjusts text view position and scale.
   * @param {State} state
   */
  adjustTextView(state) {
    if (!this.text)
      return;
    const activeView = this.getStateView(this.state);
    if (activeView) {
      if (!this.options?.ignoreRefitting) {
        this._views.textView.scale.set(this._defaultTextScale.x, this._defaultTextScale.y);
      }
      fitToView(activeView, this._views.textView, this.padding, false);
      this._views.textView.x = activeView.x + activeView.width / 2;
      this._views.textView.y = activeView.y + activeView.height / 2;
    }
    this._views.textView.anchor.set(0.5);
    this.setOffset(this._views.textView, state, this.textOffset);
  }
  /**
   * Adjusts icon view position and scale.
   * @param {State} state
   */
  adjustIconView(state) {
    if (!this._views.iconView) {
      return;
    }
    const activeView = this.getStateView(state);
    if (!activeView) {
      return;
    }
    if (!this.options?.ignoreRefitting) {
      this._views.iconView.scale.set(this._defaultIconScale.x, this._defaultIconScale.y);
    }
    fitToView(activeView, this._views.iconView, this.padding, false);
    this._views.iconView.anchor?.set(0);
    this._views.iconView.x = activeView.x + activeView.width / 2 - this._views.iconView.width / 2;
    this._views.iconView.y = activeView.y + activeView.height / 2 - this._views.iconView.height / 2;
    this.setOffset(this._views.iconView, state, this.iconOffset);
  }
  /**
   * Reset views positions according to the button anchor setting.
   * We have to set the anchor position for each view individually, as each of them
   * can be a different type of view (container without anchor, sprite with anchor, etc)
   * we have to reset all anchors to 0,0 and then set the positions manually.
   */
  updateAnchor() {
    if (!this._views)
      return;
    const anchorX = this.anchor.x ?? 0;
    const anchorY = this.anchor.y ?? 0;
    const views = [this._views.defaultView, this._views.hoverView, this._views.pressedView, this._views.disabledView];
    views.forEach((view) => {
      if (!view)
        return;
      view.anchor?.set(0);
      view.x = -view.width * anchorX;
      view.y = -view.height * anchorY;
    });
    if (this._views.defaultView) {
      const { x, y, width, height } = this._views.defaultView;
      this.hitArea = new Rectangle(x, y, width, height);
    }
    this.adjustIconView(this.state);
    this.adjustTextView(this.state);
  }
  /**
   * Sets the default view of the button.
   * @param { string | Container } view - string (path to the image) or a Container-based view
   */
  set defaultView(view) {
    this.updateView("defaultView", view);
  }
  /** Returns the default view of the button. */
  get defaultView() {
    return this._views.defaultView;
  }
  /**
   * Sets the hover view of the button.
   * @param { string | Container } view - string (path to the image) or a Container-based view
   */
  set hoverView(view) {
    this.updateView("hoverView", view);
    if (this._views.hoverView && this.state !== "hover") {
      this._views.hoverView.visible = false;
    }
  }
  /** Returns the hover view of the button. */
  get hoverView() {
    return this._views.hoverView;
  }
  /** Sets the pressed view of the button. */
  set pressedView(view) {
    this.updateView("pressedView", view);
    if (this._views.pressedView) {
      this._views.pressedView.visible = false;
    }
  }
  /** Returns the pressed view of the button. */
  get pressedView() {
    return this._views.pressedView;
  }
  /** Sets the disabled view of the button. */
  set disabledView(view) {
    this.updateView("disabledView", view);
    if (this._views.disabledView) {
      this._views.disabledView.visible = false;
    }
  }
  /** Returns the disabled view of the button. */
  get disabledView() {
    return this._views.disabledView;
  }
  /**
   * Helper method to update or cleanup button views.
   * @param { 'defaultView' | 'hoverView' | 'pressedView' | 'disabledView' } viewType - type of the view to update
   * @param { string | Container | null } view - new view
   */
  updateView(viewType, view) {
    if (view === void 0)
      return;
    this.removeView(viewType);
    if (view === null) {
      return;
    }
    if (this.options?.nineSliceSprite) {
      if (typeof view === "string") {
        this._views[viewType] = new NineSliceSprite({
          texture: Texture.from(view),
          leftWidth: this.options.nineSliceSprite[0],
          topHeight: this.options.nineSliceSprite[1],
          rightWidth: this.options.nineSliceSprite[2],
          bottomHeight: this.options.nineSliceSprite[3]
        });
      } else {
        console.warn("NineSliceSprite can not be used with views set as Container.");
      }
    }
    if (!this._views[viewType]) {
      this._views[viewType] = getView(view);
    }
    this.setOffset(this._views[viewType], this.state, this.offset);
    if (!this._views[viewType].parent) {
      this.innerView.addChild(this._views[viewType]);
    }
    this.updateAnchor();
    if (this._views.iconView) {
      this.innerView.addChild(this._views.iconView);
    }
    if (this._views.textView) {
      this.innerView.addChild(this._views.textView);
    }
    this.setState(this.state, true);
  }
  /**
   * Removes button view by type
   * @param {'defaultView' | 'hoverView' | 'pressedView' | 'disabledView'} viewType - type of the view to remove
   */
  removeView(viewType) {
    if (this._views[viewType]) {
      this.innerView.removeChild(this._views[viewType]);
      this._views[viewType] = null;
    }
  }
  /**
   * Sets the textView of the button.
   * @param { string | number | PixiText | Text | BitmapText | HTMLText } textView - string, text or pixi text instance.
   */
  set textView(textView) {
    if (textView === void 0)
      return;
    this.removeView("textView");
    if (textView === null) {
      return;
    }
    this.createTextView(textView);
  }
  /**
   * Returns the text view of the button.
   * @returns pixi text instance or undefined.
   */
  get textView() {
    return this._views.textView;
  }
  /**
   * Sets the iconView of the button.
   * @param { string | Container } view - string (path to the image) or a Container-based view
   */
  set iconView(view) {
    if (view === void 0)
      return;
    this.removeView("iconView");
    if (view === null) {
      return;
    }
    this._views.iconView = getView(view);
    if (this.options?.defaultIconScale === void 0) {
      const { x, y } = this._views.iconView.scale;
      this._defaultIconScale = { x, y };
    }
    if (!this._views.iconView.parent) {
      this.innerView.addChild(this._views.iconView);
    }
    this.setState(this.state, true);
  }
  /** Returns the icon view of the button. */
  get iconView() {
    return this._views.iconView;
  }
  /**
   * Starts animation for the current button state if configured.
   * @param {State} state
   */
  playAnimations(state) {
    if (!this.animations)
      return;
    if (state === "default" && !this.originalInnerViewState) {
      this.originalInnerViewState = {
        x: this.innerView.x,
        y: this.innerView.y,
        width: this.innerView.width,
        height: this.innerView.height,
        scale: {
          x: this.innerView.scale.x,
          y: this.innerView.scale.y
        }
      };
      const defaultStateAnimation = this.animations?.default;
      if (defaultStateAnimation) {
        this.innerView.x = defaultStateAnimation.props.x ?? this.originalInnerViewState.x;
        this.innerView.y = defaultStateAnimation.props.y ?? this.originalInnerViewState.y;
        this.innerView.width = defaultStateAnimation.props.width ?? this.originalInnerViewState.width;
        this.innerView.height = defaultStateAnimation.props.height ?? this.originalInnerViewState.height;
        this.innerView.scale.x = defaultStateAnimation.props.scale.x ?? this.originalInnerViewState.scale.x;
        this.innerView.scale.y = defaultStateAnimation.props.scale.y ?? this.originalInnerViewState.scale.y;
        return;
      }
    }
    const stateAnimation = this.animations[state] ?? this.animations.default;
    if (stateAnimation) {
      const data = stateAnimation;
      this.defaultDuration = data.duration;
      new Tween(this.innerView).to(data.props, data.duration).start();
      return;
    }
    new Tween(this.innerView).to(this.originalInnerViewState, this.defaultDuration).start();
  }
  initStateControl() {
    this.onDown.connect(() => {
      this.setState("pressed");
    });
    this.onUp.connect(() => {
      isMobile.any ? this.setState("default") : this.setState("hover");
    });
    this.onUpOut.connect(() => {
      this.setState("default");
    });
    this.onOut.connect(() => {
      if (!this.button.isDown) {
        this.setState("default");
      }
    });
    this.onPress.connect(() => {
      isMobile.any ? this.setState("default") : this.setState("hover");
    });
    this.onHover.connect(() => {
      if (!this.button.isDown) {
        isMobile.any ? this.setState("default") : this.setState("hover");
      }
    });
  }
  /**
   * Sets the button padding.
   * @param {number} padding - padding of the button text and icon views.
   */
  set padding(padding) {
    this._padding = padding;
    this.adjustTextView(this.state);
    this.adjustIconView(this.state);
  }
  /** Returns the button padding. */
  get padding() {
    return this._padding;
  }
  /**
   * Sets the button offset.
   * @param { { x?: number; y?: number } } offset - offset of the button.
   * Can be set for each state of the button.
   */
  set offset(offset) {
    this._offset = offset;
    this.updateAnchor();
  }
  /** Returns the button offset. */
  get offset() {
    return this._offset;
  }
  /**
   * Sets the button text offset.
   * @param { { x?: number; y?: number } } textOffset - offsets of the button text view.
   * can be set for each state of the button.
   */
  set textOffset(textOffset) {
    this._textOffset = textOffset;
    this.adjustTextView(this.state);
  }
  /** Returns the button text offset. */
  get textOffset() {
    return this._textOffset;
  }
  /**
   * Sets the base scale for the text view to take into account when fitting inside the button.
   * @param {Pos | number} scale - base scale of the text view.
   */
  set defaultTextScale(scale) {
    if (scale === void 0)
      return;
    this.options.defaultTextScale = scale;
    const isNumber = typeof scale === "number";
    this._defaultTextScale.x = isNumber ? scale : scale.x ?? 1;
    this._defaultTextScale.y = isNumber ? scale : scale.y ?? 1;
    this.adjustTextView(this.state);
  }
  /** Returns the text view base scale. */
  get defaultTextScale() {
    return this.defaultTextScale;
  }
  /**
   * Sets the base scale for the icon view to take into account when fitting inside the button.
   * @param {Pos | number} scale - base scale of the icon view.
   */
  set defaultIconScale(scale) {
    if (scale === void 0)
      return;
    this.options.defaultIconScale = scale;
    const isNumber = typeof scale === "number";
    this._defaultIconScale.x = isNumber ? scale : scale.x ?? 1;
    this._defaultIconScale.y = isNumber ? scale : scale.y ?? 1;
    this.adjustIconView(this.state);
  }
  /** Returns the icon view base scale. */
  get defaultIconScale() {
    return this.defaultIconScale;
  }
  /**
   * Sets width of a FancyButtons state views.
   * If nineSliceSprite is set, then width will be set to nineSliceSprites of a views.
   * If nineSliceSprite is not set, then width will control components width as Container.
   * @param width - Width value.
   */
  set width(width) {
    if (this.options?.nineSliceSprite) {
      if (this._views.defaultView) {
        this._views.defaultView.width = width;
      }
      if (this._views.hoverView) {
        this._views.hoverView.width = width;
      }
      if (this._views.pressedView) {
        this._views.pressedView.width = width;
      }
      if (this._views.disabledView) {
        this._views.disabledView.width = width;
      }
      this.adjustTextView(this.state);
      this.adjustIconView(this.state);
      this.updateAnchor();
    } else {
      super.width = width;
    }
  }
  /** Gets width of a FancyButton. */
  get width() {
    return super.width;
  }
  /**
   * Sets height of a FancyButtons state views.
   * If nineSliceSprite is set, then height will be set to nineSliceSprites of a views.
   * If nineSliceSprite is not set, then height will control components height as Container.
   * @param height - Height value.
   */
  set height(height) {
    if (this.options?.nineSliceSprite) {
      if (this._views.defaultView) {
        this._views.defaultView.height = height;
      }
      if (this._views.hoverView) {
        this._views.hoverView.height = height;
      }
      if (this._views.pressedView) {
        this._views.pressedView.height = height;
      }
      if (this._views.disabledView) {
        this._views.disabledView.height = height;
      }
      this.adjustTextView(this.state);
      this.adjustIconView(this.state);
      this.updateAnchor();
    } else {
      super.height = height;
    }
  }
  /** Gets height of a FancyButton. */
  get height() {
    return super.height;
  }
}

export { FancyButton };

