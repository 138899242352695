import {CommonModule} from '@angular/common';
import {Component, ElementRef, HostListener, inject, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {AuthService, ThemeService} from '@core/services';
import {ModalService} from '@core/services/modal/modal.service';
import {LoginComponent} from '@pages/auth/login/login.component';
import {PurchaseComponent} from '@pages/purchase/purchase.component';
import {animate, style, transition, trigger} from '@angular/animations';
import {DropdownMenuComponent} from '@shared/components/dropdown-menu/dropdown-menu.component';
import {DropdownMenuItemComponent} from '@shared/components/dropdown-menu/dropdown-menu-item/dropdown-menu-item.component';
import {ChatSocketService} from '@core/services/chat/chat-socket.service';
import {AssetConstants} from '@core/constants/asset-constants';
import convertCoin from '@core/utils/helpers/coinConverter';
import {PrizeWheelComponent} from '@shared/components/prize-wheel/prize-wheel.component';
import {TranslocoPipe} from '@ngneat/transloco';
import {ConstantService} from '@core/services/common/constant.service';
import {i18nConstants} from '@core/constants/i18n-constants';
import {GetAppComponent} from '@shared/components/get-app/get-app.component';
import {environment} from '@env/environment';

@Component({
    selector: 'app-header',
    standalone: true,
    imports: [CommonModule, DropdownMenuComponent, DropdownMenuItemComponent, TranslocoPipe],
    animations: [
        trigger('fadeRollIn', [
            transition(':enter', [
                style({opacity: 0, transform: 'translateX(-30px)'}),
                animate('300ms', style({opacity: 1, transform: 'translateX(0px)'})),
            ]),
            transition(':leave', [
                style({opacity: 1, transform: 'translateX(0px)'}),
                animate('300ms', style({opacity: 0, transform: 'translateX(-30px)'})),
            ]),
        ]),
        trigger('fadeIn', [transition(':enter', [style({opacity: 0}), animate('300ms', style({opacity: 1}))])]),
    ],
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
    constantService = inject(ConstantService);
    themeService = inject(ThemeService);
    authService = inject(AuthService);
    chatSocketService = inject(ChatSocketService);
    @ViewChild('dropdownMenu') dropdownMenu!: ElementRef;
    router = inject(Router);
    screenWidth: number = window.innerWidth;
    hideHeader = false;
    isMobileMenuOpen = false;
    isDropdownMenuOpen = false;
    isAgent = false;
    constructor(private modalService: ModalService) {
        this.screenWidth = window.innerWidth;

        this.router.events.subscribe(val => {
            if (val instanceof NavigationEnd) {
                this.hideHeader = /\/live\/\d+/.test(this.router.url);
            }
        });
    }

    ngOnInit() {
        this.isAgent = this.authService.userDetail.userLevel != 0;
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.screenWidth = window.innerWidth;
    }

    logout() {
        this.authService.logout();
    }

    openLoginComponent() {
        this.modalService.open(LoginComponent, {position: 'center'});
    }
    openPurchaseModal() {
        if (this.authService.isSkipped) {
            this.openLoginComponent();
            return;
        }
        if (this.isAgent) return;
        this.modalService.open(PurchaseComponent);
    }

    toggleDropdownMenu() {
        this.isMobileMenuOpen = !this.isMobileMenuOpen;
    }

    routeToHome() {
        this.router.navigateByUrl('/');
    }

    openSpinWheel() {
        this.modalService.open(PrizeWheelComponent, {position: 'center'});
    }

    openGetAppModal() {
        this.modalService.open(GetAppComponent);
    }
    routeToLeaderboard() {
        this.router.navigateByUrl('/leaderboard');
    }

    protected readonly AssetConstants = AssetConstants;
    protected readonly convertCoin = convertCoin;
    protected readonly i18nConstants = i18nConstants;
    protected readonly environment = environment;
}
