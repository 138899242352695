import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MenuItem} from '@shared/components/dropdown-menu/menu-items';
import {NgIf} from '@angular/common';
import {TranslocoPipe} from '@ngneat/transloco';

@Component({
    selector: 'app-dropdown-menu-item',
    standalone: true,
    imports: [NgIf, TranslocoPipe],
    templateUrl: './dropdown-menu-item.component.html',
    styleUrl: './dropdown-menu-item.component.scss',
})
export class DropdownMenuItemComponent {
    @Input() option!: MenuItem;
    @Output() action: EventEmitter<void> = new EventEmitter<void>();
}
